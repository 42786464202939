import React, { forwardRef } from "react"
import { func, string, node } from "prop-types"
import styled from "@emotion/styled"
import { FormattedMessage } from "gatsby-plugin-intl"
import { a } from "react-spring"
import { mqTablet } from "static/constants"
import { CONTAINER_BG, HEADER_BORDER } from "static/global-css"

const Terminal = forwardRef(
  ({ children, setIsClosed, setIsMinimized, titleId, ...rest }, ref) => {
    function handleClose() {
      setIsClosed(true)
    }

    function handleMinimize() {
      setIsMinimized(true)
    }

    function handleMaximize() {
      setIsMinimized(false)
    }

    return (
      <Container {...rest} ref={ref}>
        <Header>
          <Wrapper>
            <Circle
              type="button"
              color="rgb(250, 97, 89)"
              onClick={handleClose}
            />
            <Circle
              type="button"
              color="rgb(250, 191, 47)"
              onClick={handleMinimize}
            />
            <Circle
              type="button"
              color="rgb(40, 202, 65)"
              onClick={handleMaximize}
            />
          </Wrapper>
          <span>
            &sim;&#8725;
            <FormattedMessage id={titleId} />
          </span>
          <Wrapper />
        </Header>
        {children}
      </Container>
    )
  }
)

Terminal.propTypes = {
  children: node.isRequired,
  setIsMinimized: func.isRequired,
  titleId: string.isRequired,
}

export default Terminal

const Container = styled(a.section)({
  fontFamily: `'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace`,
  background: `${CONTAINER_BG}`,
  borderRadius: 7,
  border: `1px solid ${HEADER_BORDER}`,
  display: `flex`,
  flexDirection: `column`,
})

const Header = styled.header({
  display: `flex`,
  alignItems: `center`,
  fontWeight: `normal`,
  padding: `15px 15px 25px`,
})

const Circle = styled.button(({ color }) => ({
  background: color,
  width: 12,
  height: 12,
  borderRadius: `50%`,
  marginRight: 10,
  [mqTablet]: {
    display: `none`,
  },
}))

const Wrapper = styled.div({
  flex: 1,
})
