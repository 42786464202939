import experiencesJson from "json/experiences.json"
import { arrayOf, string, shape, instanceOf } from "prop-types"

export const experiences = experiencesJson
  .map(json => experienceFromJson(json))
  .sort((a, b) => b.to - a.to)

export const projects = [].concat(
  ...experiences.map(exp => {
    const { company, projects } = exp
    return projects.map(proj => {
      return {
        ...proj,
        company,
      }
    })
  })
)

export function experienceFromJson(json) {
  const { from, to, ...rest } = json
  return {
    ...rest,
    from: new Date(from),
    to: to ? new Date(to) : new Date(),
  }
}

export const experienceShape = shape({
  company: string.isRequired,
  contact: string,
  from: instanceOf(Date).isRequired,
  jobTitle: string.isRequired,
  link: string,
  projects: arrayOf(
    shape({
      description: string.isRequired,
      stack: string.isRequired,
      title: string.isRequired,
      type: string.isRequired,
    })
  ).isRequired,
  type: instanceOf(Date).isRequired,
})
