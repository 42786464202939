import React from "react"
import { arrayOf, bool, shape } from "prop-types"
import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"
import { config, a, useSpring } from "react-spring"
import { useMeasure } from "helpers/hooks"
import { CONTAINER_FONT } from "static/global-css"

const ExperienceProjects = ({ isOpen, projects }) => {
  const [bind, { height }] = useMeasure()
  const springProps = useSpring({
    config: config.stiff,
    height: isOpen ? height + 10 : 0, // manually adds padding +10
    opacity: isOpen ? 1 : 0,
    transform: `translate3d(0, ${isOpen ? 0 : -20}px, 0)`,
  })

  return (
    <a.div style={{ overflow: `hidden`, ...springProps }}>
      <ExpandRow {...bind}>
        {projects.map((p, i) => (
          <li key={p.id}>
            <ProjectLink to="/work" state={{ target: p.id }}>
              <span>{`${i + 1}. ${p.title}`}</span>
              <Devices>&#91;{p.devices.join(` / `)}&#93;</Devices>
            </ProjectLink>
          </li>
        ))}
      </ExpandRow>
    </a.div>
  )
}

ExperienceProjects.propTypes = {
  isOpen: bool.isRequired,
  projects: arrayOf(shape({})),
}

export default ExperienceProjects

const ExpandRow = styled.ol({
  borderTop: `1px dashed ${CONTAINER_FONT}`,
  padding: `5px 0`,
  margin: 0,
  fontSize: 11,
  listStyle: `none`,
})

const ProjectLink = styled(Link)({
  display: `flex`,
  justifyContent: `space-between`,
  alignItems: `center`,
  fontWeight: `normal`,
  textDecoration: `none`,
  color: `inherit`,
  borderBottom: `1px solid transparent`,
  transition: "all 0.15s ease-in-out",
  ":hover": {
    borderBottom: `1px solid ${CONTAINER_FONT}`,
    filter: `brightness(150%)`,
  },
})

const Devices = styled.div({
  opacity: 0.5,
})
