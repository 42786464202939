import React, { forwardRef, useEffect, useState } from "react"
import { number } from "prop-types"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { config, useSpring } from "react-spring"
import { HIGHLIGHT_FONT } from "static/global-css"
import { useWindowSize } from "helpers/hooks"
import { experiences } from "models/experience"
import T from "components/terminal"
import ExperienceProjects from "components/experience-projects"

const Experiences = forwardRef(({ top, ...rest }, ref) => {
  const intl = useIntl()
  const { height: winHeight } = useWindowSize()
  const [elementHeight, setElementHeight] = useState(`auto`)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [isClosed, setIsClosed] = useState(false)
  const [isMinimized, setIsMinimized] = useState(false)
  const [visibleState, setVisibleState] = useState(
    experiences.reduce((obj, exp) => {
      obj[exp.company] = false
      return obj
    }, {})
  )

  useEffect(() => {
    setHeaderHeight(ref.current.firstElementChild.clientHeight)
  }, [ref])

  const minimizeProps = useSpring({
    config: isMinimized
      ? { mass: 1, tension: 500, friction: 0, clamp: true }
      : config.stiff,
    from: { opacity: 0, transform: `scaleX(0) scaleY(0)` },
    to: {
      opacity: 1,
      top: isMinimized ? winHeight - headerHeight : winHeight * 0.15,
      right: isMinimized ? `2vw` : `5vw`,
      width: isMinimized ? `20vw` : `25vw`,
      transform: isClosed ? `scaleX(0) scaleY(0)` : `scaleX(1) scaleY(1)`,
    },
  })

  function handleMinimize(didMinimize) {
    const newHeight = didMinimize ? headerHeight : `auto`
    setIsMinimized(didMinimize)
    setElementHeight(newHeight)
  }

  function formatDate(date) {
    return intl
      .formatDate(date, {
        year: `2-digit`,
        month: `2-digit`,
        day: `2-digit`,
      })
      .replace(/\//g, `.`)
  }

  function handleExpand(company) {
    setVisibleState({ ...visibleState, [company]: !visibleState[company] })
  }

  return (
    <Terminal
      titleId="experiences.title"
      setIsClosed={setIsClosed}
      setIsMinimized={handleMinimize}
      style={minimizeProps}
      ref={ref}
      height={elementHeight}
      {...rest}
    >
      <Content>
        {experiences.map((experience, i) => (
          <Container key={experience.company}>
            <Title index={i}>{experience.company}</Title>
            <Subtitle index={i}>{experience.jobTitle}</Subtitle>
            <Years index={i}>{`${formatDate(experience.from)} - ${formatDate(
              experience.to
            )}`}</Years>

            <Expand
              type="button"
              index={i}
              onClick={() => handleExpand(experience.company)}
            >
              {visibleState[experience.company] ? `- ` : `+ `}
              <FormattedMessage id="projects" />
            </Expand>
            <ExperienceProjects
              isOpen={visibleState[experience.company]}
              projects={experience.projects}
            />
          </Container>
        ))}
      </Content>
    </Terminal>
  )
})

Experiences.defaultProps = {
  top: 0,
}

Experiences.propTypes = {
  top: number,
}

export default Experiences

const Terminal = styled(T)({
  position: `absolute`,
  maxWidth: 400,
  minWidth: 280,
  boxShadow: `0 2px 4px rgba(0, 0, 0, .5)`,
  zIndex: 200,
})

const Container = styled.div({
  position: `relative`,
  padding: `0 0 30px`,
})

const Content = styled.main({
  flex: 1,
  overflowX: `hidden`,
  overflowY: `auto`,
  padding: `0 15px`,
  maskImage: `linear-gradient(to bottom, black 90%, transparent 100%)`,
  overflowStyle: `none`,
  scrollbarWidth: `none`,
  msOverflowStyle: `none`,
  "&::-webkit-scrollbar": {
    width: 0,
    background: `transparent`,
    display: `none`,
  },
})

const typing = keyframes`
  from {width: 0}
  to {width: 100%}
`

const Typewriter = styled.p({
  overflow: `hidden`,
  whiteSpace: `nowrap`,
  width: 0,
})

function calcDelay(index, row) {
  const elements = 4
  const duration = 0.25
  const cycle = elements * duration
  return index * cycle + row * duration
}

const Title = styled(Typewriter)(({ index }) => ({
  fontSize: 18,
  fontWeight: `bold`,
  color: `${HIGHLIGHT_FONT}`,
  animation: `${typing} .5s steps(32, end) ${calcDelay(index, 0)}s forwards`,
}))

const Subtitle = styled(Typewriter)(({ index }) => ({
  fontSize: 12,
  fontWeight: `normal`,
  animation: `${typing} 1s steps(32, end) ${calcDelay(index, 1)}s forwards`,
}))

const Years = styled(Typewriter)(({ index }) => ({
  fontSize: 11,
  fontWeight: `light`,
  animation: `${typing} 1s steps(32, end) ${calcDelay(index, 2)}s forwards`,
}))

const Expand = styled.button(({ index }) => ({
  fontSize: 12,
  fontWeight: `bold`,
  overflow: `hidden`,
  whiteSpace: `nowrap`,
  width: 0,
  textAlign: `left`,
  background: `none`,
  cursor: `pointer`,
  marginBottom: 10,
  animation: `${typing} 1s steps(32, end) ${calcDelay(index, 3)}s forwards`,
  ":hover": {
    filter: `brightness(150%)`,
  },
}))
